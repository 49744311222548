export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2023 Save Thy Oceans. Powered by{' '}
            <a href='https://commerce.coinbase.com/checkout/8287f873-8876-446d-93d9-0eb567d2680b' rel='nofollow'>
              Xetawallet
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
