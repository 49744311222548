export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='https://commerce.coinbase.com/checkout/8287f873-8876-446d-93d9-0eb567d2680b'>
            SAVE THY OCEANS !!
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#campaigns' className='page-scroll'>
                Campaigns
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='#ocean' className='page-scroll'>
                Oceans
              </a>
            </li>
            <li>
              <a href='#references' className='page-scroll'>
                References
              </a>
            </li>
            <li>
              <a href='https://commerce.coinbase.com/checkout/8287f873-8876-446d-93d9-0eb567d2680b' className='btn btn-custom btn-lg page-scroll'>
                Contribute
              </a>
            </li>       
          </ul>
        </div>
      </div>
    </nav>
  )
}
